import './Checkbox.css'

const Checkbox = ({ label, checked, onChange }) => {
  return (
      <div className="checkbox">
        <label className="checkbox-wrapper">
          <input type="checkbox" className="checkbox-input" checked={checked} onChange={onChange} />
          <span className="checkbox-tile">
            <span className="checkbox-icon">
              <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="75" r="5" fill="currentColor"/>
                <circle cx="31" cy="75" r="3" fill="currentColor"/>
                <circle cx="53" cy="68" r="3" fill="currentColor"/>
                <circle cx="68" cy="52" r="3" fill="currentColor"/>
                <circle cx="80" cy="58" r="3" fill="currentColor"/>
                <circle cx="92" cy="34" r="3" fill="currentColor"/>
                <circle cx="77" cy="28" r="4" fill="currentColor"/>
                <line x1="11" y1="75" x2="31" y2="75" stroke="currentColor" strokeWidth="2"/>
                <line x1="31" y1="75" x2="53" y2="68" stroke="currentColor" strokeWidth="2"/>
                <line x1="53" y1="68" x2="68" y2="52" stroke="currentColor" strokeWidth="2"/>
                <line x1="68" y1="52" x2="80" y2="58" stroke="currentColor" strokeWidth="2"/>
                <line x1="80" y1="58" x2="92" y2="34" stroke="currentColor" strokeWidth="2"/>
                <line x1="92" y1="34" x2="77" y2="28" stroke="currentColor" strokeWidth="2"/>
                <line x1="77" y1="28" x2="68" y2="52" stroke="currentColor" strokeWidth="2"/>
              </svg>
            </span>
            {checked ? <strong className="checkbox-label">{label}</strong> : <label className="checkbox-label">{label}</label>}
            
          </span>
        </label>
      </div>
  );
};

export default Checkbox;