import Mockup from './Mockup'
import CheckoutForm from './CheckoutForm';
import ChooseBlanket from './ChooseBlanket';
import './CheckoutForm.css'
import logo from './newlogoblack.png'
import blanketImage from './sherpaimage.jpg'
import velve from './velve.png'
import sherpa from './sherpa.png'
import Header from '../Static/Components/Header';
import Footer from '../Static/Components/Footer';


import './Cart.css'

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion, {FAQAccordion} from './Accordion';

export default function Cart() {
  const [productsInfo, setProductsInfo] = useState()
  const [info, setInfo] = useState({name: 'Velveteen Plush Blanket 60x80\'', price: '7299', shipping: {'other': 999}})
  const [product, setProduct] = useState('002')

  useEffect(() => {
    fetch('https://server-5ei7ectbla-uc.a.run.app/get_product_info').then(res => res.json()).then(data => {
      setProductsInfo(data.product_info)})
  }, [])

  useEffect(() => {
    if (productsInfo && productsInfo.length > 0) {
      for (let i = 0; i < productsInfo.length; i++) {
        if (productsInfo[i][0] === product) {
          setInfo({name: productsInfo[i][1], price: productsInfo[i][2], shipping: productsInfo[i][3]})
          break;
        }
      }
    }
  }, [productsInfo, product])

  const [index, setIndex] = useState(0)

  let activeIndex
  switch(index) {
    case 0:
      activeIndex = <ChooseBlanket index={index} setIndex={setIndex} product={product} setProduct={setProduct} info={info} />
      break;
    case 1:
      activeIndex = <CheckoutForm product={product} shipping={info.shipping} price={info.price} index={index} setIndex={setIndex} />
      break;
    default:
      activeIndex = <CheckoutForm product={product} price={info.price} index={index} setIndex={setIndex} />
      break;
  }

  return (
    <div className='cart'>
      <Header background={'#f1f1f1'} color={'black'} sticky='sticky' />
      
      <div className='cart-product-container'>
        <div className='cart-mockup-container'>
          <Mockup info={info} product={product} setProduct={setProduct} index={index} setIndex={setIndex} />
          {index === 0 ?
          <div className='desktopOnly'>
            <Accordion />
          
            <div style={{width: '90%', margin: 'auto 5%', wordWrap: 'break-word'}}>
              <h1 style={{marginBottom: '5px', marginTop: '25px'}}>Velveteen Plush</h1>
              <img style={{width: '100%', borderRadius: '10px'}} src={velve} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px', fontSize: '24px'}}>Create a cozy and warm atmosphere at home with this plush velveteen blanket. It is soft to touch and perfect for any home.</p>
              <p style={{fontSize: '24px'}}>The print is on only one side and the base color (the back side) is white which contrasts the dark night sky very well!  </p>
              <h1 style={{marginBottom: '5px', marginTop: '25px'}}>Sherpa Fleece</h1>
              <img style={{width: '100%', borderRadius: '10px'}} src={sherpa} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px', fontSize: '24px'}}>Unbelievably fluffy and warm - this high quality cozy fleece blanket is impossible to leave behind wherever one might go. The perfect size for snuggling on the couch, by the fireplace or at outdoor events.</p>
              <p style={{fontSize: '24px'}}>The print is on only one side and the base color (the back side) is tan.</p>
              <h1 style={{marginBottom: '0px', marginTop: '25px'}}>Key Features</h1>
              <img style={{width: '100%', borderRadius: '10px'}} src={blanketImage} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px', fontSize: '24px'}}>Made with 100% polyester for a soft feel. Velveteen Plush Blanket has light-weight fabric that feels extra soft to the touch. Do not underestimate the light-weight fabric though as it will surely keep you warm!</p>
              <p style={{fontSize: '24px'}}>The Sherpa Fleece Blanket has heavy 3mm fabric for extra warmth and coziness.</p>
            </div>

            <h1 style={{marginLeft: '5%', marginBottom: '0px', marginTop: '30px'}}>FAQs</h1>
            <FAQAccordion />
          </div>
          :
          <></>
          }
        </div>
        <div className='cartOptions'>
          {activeIndex}
        </div>
        {index === 0 ?
          <div className='mobileOnly' style={{margin: '50px auto'}}>
            <Accordion />
            <div style={{width: '90%', margin: 'auto 5%', marginTop: '50px', wordWrap: 'break-word'}}>
              <h2 style={{marginBottom: '5px', marginTop: '25px'}}>Velveteen Plush</h2>
              <img style={{width: '100%', borderRadius: '10px'}} src={velve} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px'}}>Create a cozy and warm atmosphere at home with this plush velveteen blanket. It is soft to touch and perfect for any home.</p>
              <p>The print is on only one side and the base color (the back side) is white which contrasts the dark night sky very well!  </p>
              <h2 style={{marginBottom: '5px', marginTop: '25px'}}>Sherpa Fleece</h2>
              <img style={{width: '100%', borderRadius: '10px'}} src={sherpa} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px'}}>Unbelievably fluffy and warm - this high quality cozy fleece blanket is impossible to leave behind wherever one might go. The perfect size for snuggling on the couch, by the fireplace or at outdoor events.</p>
              <p>The print is on only one side and the base color (the back side) is tan.</p>
              <h2 style={{marginBottom: '0px', marginTop: '25px'}}>Key Features</h2>
              <img style={{width: '100%', borderRadius: '10px'}} src={blanketImage} alt='sherpa blanket' /> 
              <p style={{marginTop: '0px'}}>Made with 100% polyester for a soft feel. Velveteen Plush Blanket has light-weight fabric that feels extra soft to the touch. Do not underestimate the light-weight fabric though as it will surely keep you warm!</p>
              <p>The Sherpa Fleece Blanket has heavy 3mm fabric for extra warmth and coziness.</p>
            </div>
            <h1 style={{marginLeft: '5%', marginBottom: '0px', marginTop: '30px'}}>FAQs</h1>
            <FAQAccordion />
          </div>
          :
          <></>
          }
      </div>

      

      <Footer background='#f1f1f1' color='black' />
    </div>
  );
}