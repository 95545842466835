import React from 'react';

class InputTime extends React.Component {
  render() {
    return (
      <input type="time" value={this.props.value} onChange={this.props.onChange} style={{
        backgroundColor: '#1a1d28',
        padding: '15px',
        fontFamily: 'Roboto Mono, monospace',
        color: '#fff',
        fontSize: '18px',
        border: '2px solid #fff',
        outline: 'none',
        borderRadius: '10px',
      }} />
    );
  }
}

export default InputTime;