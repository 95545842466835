import './Create.css'

import Checkbox from './Inputs/Checkbox.js';
import RadioButton from './Inputs/RadioButton.js';

import { Context } from '../App.js'
import { useContext } from 'react';

export default function Design( { prev, next } ) {
  
  const {options, setOptions} = useContext(Context);

  return (
    <div className="design">
      <h1 style={{color: '#fff', textDecoration: 'underline' }}>Customize</h1>

      <div className="checkboxes">
        <Checkbox label="Constellations" checked={options.constellations} onChange={(e) => setOptions(prevOptions => ({...prevOptions, constellations: e.target.checked}))} />
        <Checkbox label="Milkyway" checked={options.milkyway} onChange={(e) => setOptions(prevOptions => ({...prevOptions, milkyway: e.target.checked}))} />
        <Checkbox label="Grid" checked={options.grid} onChange={(e) => setOptions(prevOptions => ({...prevOptions, grid: e.target.checked}))} />
      </div>
      <div className="background-container">
        <label style={{color: '#fff'}}>Background Color</label>
        <div className="background-buttons">
            <RadioButton value='#1a1d28' checked={options.background === '#1a1d28'} onChange={(e) => setOptions(prevOptions => ({...prevOptions, background: e.target.value}))} colorName={'Dark Blue'} color={'#1a1d28'} />
            <RadioButton value='#000' checked={options.background === '#000'} onChange={(e) => setOptions(prevOptions => ({...prevOptions, background: e.target.value}))} colorName={'Black'} color={'#000'} />
            <RadioButton value='#333' checked={options.background === '#333'} onChange={(e) => setOptions(prevOptions => ({...prevOptions, background: e.target.value}))} colorName={'Gray'} color={'#333'} />
        </div>
      </div>

      <div className='mobileIndexButtons' style={{width: '75%'}}>
        <h3 style={{cursor: 'pointer', margin: '10px', color: 'white', textDecoration: 'underline'}} onClick={prev}>&#9664; Back</h3>
        <h3 style={{cursor: 'pointer', margin: '10px', color: 'white', textDecoration: 'underline'}} onClick={next}>Preview &#9654;</h3>
      </div>
    </div>
  );
}