import { useState, useEffect } from "react";

import celestial from "d3-celestial";

import './Process.css'

import blanket from './tokyo.png'

function Steps({index, setIndex}) {

  const { innerWidth: width, innerHeight: height } = window;

  let newWidth;
  if(width > 1024) {newWidth = width * .9 * .25}
  else {newWidth = width * .9}

  var config = {
    width: newWidth,
    projection: "airy",
    interactive: false,
    center: [0, 0],
    background: { fill: "#000", stroke: "#fff", opacity: 1, width: 3 },
    datapath: "https://ofrohn.github.io/data/",
    stars: {
      colors: false,
      names: false,
      style: { fill: "#fff", opacity:1 },
      limit: 6,
      size: 5
    },
    constellations: { 
      lines: true,
      names: false,
      lineStyle: { stroke: "#fff", width: 1.5, opacity: 0.6 }, },
    dsos: { show: false },
    mw: {
      show: true,
      style: { fill:"#E6E6FA", opacity: 0.15 }
    },
    lines: {
      graticule: { show: true, stroke: "#cccccc", width: 0.6, opacity: 0.8,   
      lon: {pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif"}, 
      lat: {pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif"}},    
      equatorial: { show: false },  
      ecliptic: { show: false }  
    }
  };
  var map = celestial.Celestial();

  useEffect(() => {
    map.display(config);
  }, [])

  const setTokyo = () => {
    map.location([35.6764,139.65])
  }
  const setSydney = () => {
    map.location([-33.8688,151.2093])
  }

  const setConstellations = () => {
    config.constellations.lines = !config.constellations.lines
    map.apply(config)
  }
  const setMilkyway = () => {
    config.mw.show = !config.mw.show
    map.apply(config)
  }

  return(
    <div>
      <div style={index !== 0 ? { display: 'none' } : {}}>
        <h3 style={{textAlign: 'center'}}>Step 1: Choose Location and Time</h3>
        <div className="processBtn-container">
          <button className="processBtn" onClick={setTokyo}>Tokyo</button>
          <button className="processBtn" onClick={setSydney}>Sydney</button>
        </div>
      </div>

      <div style={index !== 1 ? { display: 'none' } : {}}>
        <h3 style={{textAlign: 'center'}}>Step 2: Design Your Own Starmap</h3>
        <div className="processBtn-container">
          <button className="processBtn" onClick={setConstellations}>Constellations</button>
          <button className="processBtn" onClick={setMilkyway}>Milkyway</button>
        </div>
      </div>
      <div style={index === 2 ? { display: 'none' } : {}} id="celestial-map"></div>

      <div style={index !== 2 ? { display: 'none' } : {}}>
        <h3 style={{textAlign: 'center', marginBottom: '0px'}}>Step 3: Preview Your Moment!</h3>
        <p style={{textAlign: 'center', marginTop: '0px'}}>
          <a href="/create" style={{ color: 'white' }}>
            Create Now
          </a> for Real Mockup</p>
        <img src={blanket} alt="blanket" style={{height: newWidth}} />
      </div>

    </div>

  )
}

export default function Process() {
  const [index, setIndex] = useState(0)

  const OpenBubble = 
  <svg width="25" height="25" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" stroke="white" strokeWidth='10px' fill='none' />
  </svg>
  const ClosedBubble = 
  <svg width="25" height="25" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="40" stroke="white" strokeWidth='10px' fill='none' />
    <circle cx="50" cy="50" r="30" fill='white' />
  </svg>
  const ArrowLeft = 
  <svg width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" onClick={() => setIndex((index - 1 + 3) % 3)} style={{cursor: 'pointer'}}>
    <polyline points="50,90 10,50 50,10" fill="none" stroke="white" strokeWidth='10px' />
  </svg>
  const ArrowRight = 
  <svg width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" onClick={() => setIndex((index + 1) % 3)} style={{cursor: 'pointer'}}>
    <polyline points="50,90 90,50 50,10" fill="none" stroke="white" strokeWidth='10px' />
  </svg>

  return(
    <div>
      <Steps index={index} setIndex={setIndex} />
      <div className="indexBtn-container">
        {ArrowLeft}
        {index === 0 ? ClosedBubble : OpenBubble}
        {index === 1 ? ClosedBubble : OpenBubble}
        {index === 2 ? ClosedBubble : OpenBubble}
        {ArrowRight}
      </div>
      <button className="createBtn" style={{width: '100%', height: '50px', borderRadius: '25px', fontSize: '18px', margin: '15px 0', color: 'white'}} onClick={() => {window.location.href = '/create'}}>Create Your Own</button>
    </div>
  )
}