import React from 'react';

export default function Success() {
  return (
    <div style={styles.container}>
      <h1>✅Transaction Successful</h1>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // This will make the container take up the full height of the viewport
  },
};