import React, { useState } from 'react';

import { Navigate } from 'react-router-dom'

import NavigatePage from './Navigate.js';
import { NavigateIndexButtons } from './Navigate.js';
import Design from './Design.js';
import { CustomizeIndexButtons } from './Design.js';
import CelestialMap from './CelestialMap.js';
import Footer from '../Static/Components/Footer.js';

import './Create.css';

import logo from './cosmologo.png'

function Customize() {
  // Index showing page
  // Index
  const [index, setIndex] = useState(0);
  function next() { setIndex(pIndex => pIndex + 1) }
  function prev() { setIndex(pIndex => pIndex - 1) }
  let input;


  const [toCart, setToCart] = useState(false)
  switch(index) {
    case 0:
      input = <NavigatePage next={() => next()} />; 
      break;
    case 1:
      input = <Design prev={() => prev()} next={() => {next(); setToCart(true)}} />;
      break;

  }

  return (
    <div className="customize">
      <div className='mobileHeader'>
        <div className='mobileHeaderButtons' style={{width: '80%', margin: 'auto auto'}}>
          <p style={{textDecoration: 'underline' }} onClick={() => {window.location.href = '/';}}>Home</p>
          <img src={logo} alt='logo' style={{height: '50px'}}></img>
          <p style={{textDecoration: 'underline' }} onClick={() => {window.location.href = '/contact';}}>Contact</p>
        </div>
        <hr style={{ width: '88%', height: '1px', backgroundColor: 'white', border: '0', marginTop: '0px'}} />
      </div>

      <CelestialMap className="starmapSide" toCart={toCart} />  

      <div className="left">
        <div className='desktopHeader'>
          <div className='desktopHeaderButtons'>
            <label style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {window.location.href = '/';}}>Home</label>
            <img src={logo} alt='logo' style={{height: '50px', cursor: 'pointer'}} onClick={() => {window.location.href = '/';}}></img>
            <label style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {window.location.href = '/create';}}>Contact</label>
          </div>
          <hr style={{ width: '88%', height: '1px', backgroundColor: 'white', border: '0', marginTop: '0px'}} />
        </div>
        
        <div className='input'>
          {input}
        </div>
      </div>

      <Footer background={'transparent'} mobileOnly={true} />
    </div>
  );
}

export default Customize;