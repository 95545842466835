import React from 'react';
import './RadioButton.css'

class RadioButton extends React.Component {
  render() {
    const radioButtonStyle = {
      //border: `5px solid ${this.props.color}`,
      //backgroundColor: this.props.checked ? this.props.color : 'transparent',
      border: `2px solid #fff`,
      backgroundColor: this.props.color,
    };

    return (
      <div className='radioGroup'>
        <input className='radioButton' type="radio" value={this.props.value} checked={this.props.checked} onChange={this.props.onChange} style={radioButtonStyle} />
        {this.props.checked ? <strong style={{color: '#fff'}}>{this.props.colorName}</strong> : <label style={{color: '#fff'}}>{this.props.colorName}</label>}
      </div>
      
    );
  }
}

export default RadioButton;