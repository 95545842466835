import './Header.css'
import logo from '../../cosmologo.png'

import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header({background='#430d4b', color='black', sticky='relative'}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return(
    <div className='header' style={{background: 'transparent', position: sticky, top: '0px', left: '0px'}}>
      <div className="nav" style={{background: background, color: color}}>
        <svg className='menuOpener' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ width: '24px', height: '24px', marginLeft: '5%', cursor: 'pointer' }} onClick={toggleMenu}>
          <path
            fill={color}
            d="M21,7H3C2.448,7,2,7.448,2,8s0.448,1,1,1h18c0.552,0,1-0.448,1-1S21.552,7,21,7z M21,12H3c-0.552,0-1,0.448-1,1
            s0.448,1,1,1h18c0.552,0,1-0.448,1-1S21.552,12,21,12z M21,17H3c-0.552,0-1,0.448-1,1s0.448,1,1,1h18c0.552,0,1-0.448,1-1
            S21.552,17,21,17z"
          />
        </svg>
        <div className='desktop-menu' style={{color: color}}>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>Home</label>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>FAQs</label>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>About</label>
          <label className='menuLink' onClick={() => {window.location.href = '/contact';}}>Contact</label>
        </div>
        <button className='createBtn' onClick={() => {window.location.href = '/create';}} style={{color: color, position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}>Create</button>
        <img className='header-logo' src={logo} alt='logo' onClick={() => {window.location.href = '/';}}></img>
      </div>
      {menuOpen && (
        <div className='menu' style={{color: color}}>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>Home</label>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>FAQs</label>
          <label className='menuLink' onClick={() => {window.location.href = '/';}}>About</label>
          <label className='menuLink' onClick={() => {window.location.href = '/contact';}}>Contact</label>
        </div>
      )}
      <hr style={{ width: '90%', height: '1px', backgroundColor: color, border: '0', marginTop: '0px'}} />
    </div>
  )
}