import Header from "./Components/Header"
import Footer from "./Components/Footer"
import BlanketGallery from "./Components/BlanketGallery"
import Process from "./Components/Process"

import './Home.css'

import bed from './bed.png'

export default function Home() {
  return (
    <div className="homepage">
      <Header background="transparent" color="white" />
      <div className="home">
        <div className="homepage-section">
          <div className="homepage-inner-section">
            <h1 style={{textAlign: 'center', marginBottom: '0px'}}>Wrap Up In A Special Memory</h1>
            <h3 style={{textAlign: 'center', marginTop: '5px'}}>Turn Cherished Moments into a Personalized, Heartfelt Gift</h3>
            <button className="createBtnDesktop" onClick={() => {window.location.href = '/create'}}>Create Now</button>
          </div>
          <img className="homepage-inner-section" src={bed} alt="starmap" />
        </div>
        <button className="createBtnMobile" onClick={() => {window.location.href = '/create'}}>Create Now</button>
        <hr style={{ width: '90%', height: '1px', backgroundColor: 'white', border: '0', marginTop: '0px'}} />

        <div className="homepage-section" style={{flexDirection: 'column'}}>
          <h1 style={{marginBottom: '0px'}}>The Process</h1>
          <label style={{marginTop: '0px'}}>(Demo)</label>
          <Process />
        </div>

        <hr style={{ width: '90%', height: '1px', backgroundColor: 'white', border: '0', marginTop: '0px'}} />

        <div className="homepage-section" style={{flexDirection: 'column'}}>
          <h1 style={{textAlign: 'center', marginBottom: '0px'}}>Crafting Unique Starry Moments</h1>
          <h3 style={{textAlign: 'center', marginTop: '5px', width: '60%'}}>Fully Customizable, Making Every Blanket Special and Irreplaceable</h3>
          <BlanketGallery />
        </div>
      </div>
      <Footer />
    </div>
  )
}