import React, { useEffect, useState } from 'react';

import Customize from './Customize/Create';
import Cart from './Payment/Cart';
import Success from './Payment/Success';

import { BrowserRouter as Router, Route, Routes} from "react-router-dom"
import ReturnPolicy from './Static/ReturnPolicy';
import PrivacyPolicy from './Static/PrivacyPolicy';
import Contact from './Static/Contact';
import Home from './Static/Home';

export const Context = React.createContext();

function App() {
  const [svg, setSVG] = useState('')
  const [options, setOptions] = useState({
    address: '',
    lat: 0,
    lon: 0,
    date: '2024-01-03',
    time: '23:59',
    constellations: true,
    milkyway: true,
    grid: false,
    background: '#1a1d28',
  })
  const [mockupURL, setMockupURL] = useState('');

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/create' element={<Context.Provider value={{options, setOptions, setMockupURL, setSVG}}> <Customize className="CustomizeMap" /> </Context.Provider>} />
          <Route path='/cart' element={<Context.Provider value={{options, mockupURL, setMockupURL, svg}}> <Cart /> </Context.Provider>} />
          <Route path="/transaction-successful" element={<Success />} />
          <Route path='/' element={<Home />} />
          <Route path='/return-policy' element={<ReturnPolicy />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;