import axios from 'axios';
import React, { useState } from 'react';


export function getColor( hexaColor ) {
  console.log('hexaColor:', hexaColor); // Check the value of hexaColor
  if(hexaColor === '#1a1d28') {
    return 'Dark Blue';
  } else if(hexaColor === '#000') {
    return 'Black';
  } else if(hexaColor === '#333') {
    return 'Gray';
  } else {
    return 'test';
  }
}

export function getDate(dateString) {
  // Parse the input date string
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object
  const formattedDate = new Date(year, month - 1, day);

  // Format the date as "Month dayst, year"
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  let formattedDateString = formattedDate.toLocaleDateString('en-US', options);

  // Convert day to ordinal number (1st, 2nd, 3rd, etc.)
  const dayNumber = parseInt(day, 10);
  const suffix = (() => {
    if (dayNumber >= 11 && dayNumber <= 13) {
      return 'th';
    }
    switch (dayNumber % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  })();

  // Replace day number with day number and suffix
  formattedDateString = formattedDateString.replace(/\b\d{1,2}\b/, `$&${suffix}`);

  return formattedDateString;
}

export function militaryToAMPM(time) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':');

  // Convert hours to a number
  let hourNum = parseInt(hours, 10);

  // Determine AM or PM
  const period = hourNum >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  if (hourNum === 0) {
    hourNum = 12; // 0 hour is 12 AM
  } else if (hourNum > 12) {
    hourNum -= 12; // Convert 13+ hours to 1-12
  }

  // Add leading zero to minutes if needed
  const formattedMinutes = minutes.padStart(2, '0');

  // Return the formatted time
  return `${hourNum}:${formattedMinutes} ${period}`;
}

export function getSize( variant ) {
  console.log('Variant:', variant); // Check the value of hexaColor
  if(variant === '30x40') {
    return '30×40"';
  } else if(variant === '50x60') {
    return '50×60"';
  } else if(variant === '60x80') {
    return '60×80"';
  } else {
    return 'test';
  }
}


