import './Inputs/Checkbox.js'
import './Create.css'
import InputDate from './Inputs/InputDate.js';
import InputTime from './Inputs/InputTime.js';
import './Buttons.css';

import axios from 'axios';

import { Context } from '../App.js'
import React, { useContext, useState, useEffect, useRef } from 'react';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api"


export default function NavigatePage( {next} ) {

  const {options, setOptions} = useContext(Context)

  const inputRef = useRef()

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces()
    if (place) {
      setOptions(prevOptions => ({...prevOptions, address: place.formatted_address, lat: place.geometry.location.lat(), lon: place.geometry.location.lng()}))
    }
  }

  return (
    <div className="navigate">
      <h1 style={{color: '#fff', textDecoration: 'underline' }}>Navigate</h1>

      <div style={{ marginBottom: '10px', width: '75%',  display: 'flex', flexDirection: 'column' }}>
        <label className='navigateLabel'>Location</label>
        <LoadScript googleMapsApiKey="AIzaSyDKZKMkOsg-TXnJD7GpMYqz2f4cKlmQ1D8" libraries={["places"]}>
          <StandaloneSearchBox onLoad={ref => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
            <input type="text" className="locationTextbox" placeholder='Enter Location' style={{ width: '100%' }} />
          </StandaloneSearchBox>
        </LoadScript>
      </div>

      <div style={{ marginBottom: '10px', width: '75%', display: 'flex', flexDirection: 'column' }}>
        <label className='navigateLabel'>Date</label>
        <InputDate type="date" value={options.date} onChange={(e) => setOptions(prevOptions => ({...prevOptions, date: e.target.value}))} />
      </div>

      <div style={{ marginBottom: '10px', width: '75%', display: 'flex', flexDirection: 'column' }}>
        <label className='navigateLabel'>Time</label>
        <InputTime type="time" value={options.time} onChange={(e) => setOptions(prevOptions => ({...prevOptions, time: e.target.value}))} />
      </div>

      <div className='mobileIndexButtons' style={{width: '75%'}}>
        <h3 style={{cursor: 'pointer', margin: '10px', color: 'transparent', textDecoration: 'underline'}} onClick={next}>Next &#9654;</h3>
        <h3 style={{cursor: 'pointer', margin: '10px', color: 'white', textDecoration: 'underline'}} onClick={next}>Next &#9654;</h3>
      </div>
    </div>
  );
}