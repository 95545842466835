import { useState } from 'react'
import img from './redsquare.png'
import blanket2 from './paris.png'

import './BlanketGallery.css'

const blanketEntities = [
  { img: blanket2, text: 'Paris', date: 'February 14th, 2014', time: '10:24PM' },
  { img: blanket2, text: 'London', date: 'March 29th, 2023', time: '5:02PM' }
];

function BlanketEntity( {activeImage} ) {
  return(
    <div style={{width: '50%', margin: 'auto auto'}}>
      <img src={blanketEntities[activeImage].img} alt='img' style={{width: '100%'}} />
      <h2 style={{textAlign: 'center', margin: '0 auto'}}>{blanketEntities[activeImage].text}</h2>
      <h3 style={{textAlign: 'center', margin: '0 auto'}}>{blanketEntities[activeImage].date}</h3>
      <h3 style={{textAlign: 'center', margin: '0 auto'}}>{blanketEntities[activeImage].time}</h3>
    </div>
  )
}

export default function BlanketGallery() {
  const [activeImage, setActivateImage] = useState(0)

  return (
    <div className='blanket-gallery'>
      <label className='bgBtn' onClick={ () => {setActivateImage((activeImage - 1 + blanketEntities.length) % blanketEntities.length)}}>&#8249;</label>
      <BlanketEntity style={{width: '80%'}} activeImage={activeImage} />
      <label className='bgBtn' onClick={ () => {setActivateImage((activeImage + 1) % blanketEntities.length)}}>&#8250;</label>
    </div>
  )
}