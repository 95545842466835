import React, { useState } from 'react';
import './Accordion.css'; // Import the CSS file

export default function Accordion() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion" style={{marginTop: '20px'}}>
      <div className={`section ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}>
        <div className="section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>Shipping & Delivery</div>
        <label className="section-content">We're thrilled to offer free shipping for orders destined to the USA and Canada! For customers in the USA, your order will typically arrive within 2-5 business days.</label>
        <label className="section-content">For our valued international customers outside of the USA, we provide worldwide shipping. While delivery times may vary depending on your location, please expect your order to arrive within 10-30 business days.</label>
        <label className="section-content">If you have any questions about shipping or delivery, feel free to reach out to our customer support team. We're here to help make your shopping experience as smooth as possible!</label>
      </div>
      <div className={`section ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}>
        <div className="section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>Returns & Refunds</div>
        <div className="section-content">Due to our products being print on demand and unique to every other blanket, refunds are only given if there is damage or a design malfunction and requested within 21 days. Please read our <a href="/return-policy">Return Policy</a> for more information.</div>
      </div>
      <div className={`section ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleClick(2)}>
        <div className="section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>Care Instructions</div>
        <div className="section-content">Do not dryclean; Do not iron; Tumble dry: low heat; Do not bleach; Machine wash: cold (max 30C or 90F), *hand wash will extend the quality of the product. </div>
      </div>
    </div>
  );
};

export function FAQAccordion() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      <div className={`FAQ-section ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}>
        <div className="FAQ-section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>How warm is the blanket?</div>
        <label className="section-content">Both blankets we offer are more than enough for a cold night! If you want something extra warm buy the sherpa blanket, however I can assure you both blankets are extremely cozy and warm!</label>
      </div>
      <div className={`FAQ-section ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}>
        <div className="FAQ-section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>Best gift occassions?</div>
        <div className="section-content">There are many different occassions you can use a CosmoCover as a gift, such as a wedding gift to commemorate a special memory. A birthday gift for a signifigant other (a friend too!) or to symbolize a baby's birth! In reality, it can be used for any occassion you see fit for. </div>
      </div>
      <div className={`FAQ-section ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleClick(2)}>
        <div className="FAQ-section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>What is the image resolution?</div>
        <div className="section-content">The blankets have very high resolution, roughly 150 DPI, for the smoothest of designs. This is because our designs are created using SVG images, which unlike PNG images, have infinite resolution and can be scaled to any size. </div>
      </div>
      <div className={`FAQ-section ${activeIndex === 3 ? 'active' : ''}`} onClick={() => handleClick(3)}>
        <div className="FAQ-section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>How are the blankets made?</div>
        <div className="section-content">The product goes through a dye sublimation process. Firstly, the design is printed on heat-resistant transfer paper then the blanket is embedded into the item for a long-lasting print secured with a heat press. </div>
      </div>
      <div className={`FAQ-section ${activeIndex === 4 ? 'active' : ''}`} onClick={() => handleClick(4)}>
        <div className="FAQ-section-header" style={{fontSize: '20px', fontWeight: 'bold'}}>How long will my blanket last?</div>
        <div className="section-content">The design is printed on heat-resistant transfer paper then the image is embedded into the blanket for a long-lasting print, making your blanket durable and lasting. </div>
      </div>
    </div>
  );
};