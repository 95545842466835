import Header from "./Components/Header";
import Footer from "./Components/Footer";

import contact from './contact.png'

export default function Contact() {
  return (
    <div className="contact-page">
      <Header background="transparent" color="white" />
      <div className="returnPolicy-content" style={{margin: '75px 5%'}}>
        <img src={contact} alt='contact image' style={{width: '90%', margin: 'auto auto', display: 'block'}} />
        <h1 style={{marginBottom: '0px'}}>Contact</h1>
        <label style={{marginTop: '0px'}}>Effective Date: 3/10/2024</label>
        <p>You can contact us for any reason at anytime, expect a response within 48 hours!</p>
        <p>Reach us at: cosmocovers.support@gmail.com</p>
      </div>
      <Footer background="transparent" />
    </div>
  )
}