import Header from "./Components/Header"
import Footer from "./Components/Footer"

import './pages.css'

export default function ReturnPolicy() {


  return (
    <div className="returnPolicy-page">
      <Header background="transparent" color="white" />
      <div className="returnPolicy-content" style={{margin: '75px 5%'}}>
        <h1 style={{marginBottom: '0px'}}>Privacy Policy</h1>
        <label style={{marginTop: '0px'}}>Effective Date: 3/15/2024</label>
        <p>This Privacy Policy describes how CosmoCovers collects, uses, and discloses your personal information when you visit our website cosmocovers.com or use our services.</p>

        <h3>Information We Collect</h3> 

        <h4>We collect information that you provide to us when you:</h4>
        <p>&#8226;Create an account on our Site</p>
        <p>&#8226;Contact us for support or with inquiries</p>
        <p>&#8226;Subscribe to our newsletter or updates</p>
        <p>&#8226;Make a purchase or transaction</p>
        <p>&#8226;Participate in surveys, promotions, or contests</p>

        <h4>The types of personal information we may collect include:</h4>
        <p>&#8226;Name</p>
        <p>&#8226;Email address</p>
        <p>&#8226;Mailing address</p>
        <p>&#8226;Phone number</p>
        <p>&#8226;Payment information (such as credit card details)</p>

        <h3>How We Use Your Information</h3> 

        <h4>We may use the information we collect for various purposes, including:</h4>
        <p>&#8226;Providing and improving our services</p>
        <p>&#8226;Processing transactions</p>
        <p>&#8226;Sending you updates, newsletters, or marketing communications</p>
        <p>&#8226;Responding to your inquiries or customer service requests</p>
        <p>&#8226;Conducting research and analysis</p>

        <h3>Data Security</h3>
        <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <h3>Data Sharing</h3>
        <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or required by law.</p>
        <p>We may share your information with:</p>
        <p>&#8226;Service providers who assist us in operating our website or conducting our business</p>
        <p>&#8226;Law enforcement or government authorities in response to a legal request</p>

        <h3>Cookies</h3>
        <p>We use cookies and similar tracking technologies to analyze trends, administer the website, track users' movements around the site, and gather demographic information about our user base. You can control the use of cookies at the individual browser level.</p>

        <h3>Your Rights</h3>
        <h4>You have the right to:</h4>
        <p>&#8226;Access, update, or delete your personal information</p>
        <p>&#8226;Opt-out of receiving marketing communications</p>
        <p>&#8226;Object to the processing of your personal information</p>
        <p>To exercise these rights, please contact us using the information provided below.</p>

        <h3>Changes to This Policy</h3>
        <p>We may update this Privacy Policy from time to time, and any changes will be posted on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>cosmocovers.support@gmail.com</p>

        <p>Thank you for choosing CosmoCovers. We appreciate your business and strive to provide you with the best products and service possible. </p>
      </div>

      <Footer background="transparent" />
    </div>
  )
}