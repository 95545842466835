import Header from "./Components/Header"
import Footer from "./Components/Footer"

import './pages.css'

export default function ReturnPolicy() {


  return (
    <div className="returnPolicy-page">
      <Header background="transparent" color="white" />
      <div className="returnPolicy-content" style={{margin: '75px 5%'}}>
        <h1 style={{marginBottom: '0px'}}>Return Policy</h1>
        <label style={{marginTop: '0px'}}>Effective Date: 3/10/2024</label>
        <p>We at CosmoCovers are committed to ensuring customer satisfaction with our products. If you are not entirely satisfied with your purchase, we're here to help.</p>

        <h3>Refunds will be issued only under the following circumstances:</h3> 

        <p>Damaged Product: If the product you received is damaged during shipping or upon arrival, you may be eligible for a refund. To qualify for a refund, please provide clear photographic evidence of the damage within 21 days of receiving the product.</p>

        <p>Manufacturing Error: In the event of a manufacturing error, where the product does not meet our quality standards or does not match the product description, you may be eligible for a refund. To qualify for a refund, please provide clear photographic evidence of the manufacturing error within 21 days of receiving the product.</p>

        <h3>Please note the following conditions for refunds:</h3>

        <p>&#8226;Refund requests must be made within 21 days of receiving the product.</p>
        <p>&#8226;Refunds will only be issued after the damaged product or manufacturing error has been confirmed with clear photographic evidence.</p>
        <p>&#8226;Refunds will be issued to the original payment method used for the purchase.</p>
        <p>&#8226;Shipping and handling charges are non-refundable, except in cases of manufacturing error or damage during shipping.</p>
        <p>&#8226;Refunds are processed within 3 business days after approval.</p>
        <p>&#8226;To request a refund, please email cosmocovers.support@gmail.com with the subject line "Refund Request - [Your Order Number]." Attach clear photos showing the issue along with a brief description. Our customer service team will review your request and get back to you as soon as possible.</p>

        <p>If you have any questions or concerns regarding our refund policy, please feel free to contact us at cosmocovers.support@gmail.com.</p>

        <p>Thank you for choosing CosmoCovers. We appreciate your business and strive to provide you with the best products and service possible. </p>
      </div>

      <Footer background="transparent" />
    </div>
  )
}