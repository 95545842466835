import './Mockup.css'
import blankImage from './black.png'

import React, { useState, useEffect, useContext } from 'react';

import { Context } from '../App'

export default function Mockup( { product, setProduct, index, setIndex, info}) {
  const {mockupURL} = useContext(Context)
  
  const [activeMockupURLs, setActiveMockupURLs] = useState({
    first: blankImage,
    second: blankImage,
    third: blankImage,
    fourth: blankImage
  })
  const [activeMockup, setActiveMockup] = useState(blankImage);
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    console.log(mockupURL)
    if(!firstRender){
      if(product === '000'){
        setActiveMockupURLs({first: mockupURL[0], second: mockupURL[1], third: mockupURL[2], fourth: mockupURL[3]})
        setActiveMockup(mockupURL[2])
      } else if(product === '001'){
        setActiveMockupURLs({first: mockupURL[8], second: mockupURL[9], third: mockupURL[10], fourth: mockupURL[11]})
        setActiveMockup(mockupURL[10])
      } else if(product === '002'){
        setActiveMockupURLs({first: mockupURL[16], second: mockupURL[17], third: mockupURL[18], fourth: mockupURL[19]})
        setActiveMockup(mockupURL[18])
      } else if(product === '003'){
        setActiveMockupURLs({first: mockupURL[24], second: mockupURL[27], third: mockupURL[28], fourth: mockupURL[29]})
        setActiveMockup(mockupURL[28])
      } else if(product === '004'){
        setActiveMockupURLs({first: mockupURL[30], second: mockupURL[33], third: mockupURL[34], fourth: mockupURL[35]})
        setActiveMockup(mockupURL[34])
      }
    } else {
      setFirstRender(false)
    }
  }, [product, mockupURL])

  return(
    <div className='product'>
      <div className="mockupContainer">
        <div className='mockupMiniImageContainer'>
          <img className='mockupMiniImage' onClick={ () => setActiveMockup(activeMockupURLs.first)} src={activeMockupURLs.first} alt='Mockup Loading...' />
          <img className='mockupMiniImage' onClick={ () => setActiveMockup(activeMockupURLs.second)} src={activeMockupURLs.second} alt='Mockup Loading...' />
          <img className='mockupMiniImage' onClick={ () => setActiveMockup(activeMockupURLs.third)} src={activeMockupURLs.third} alt='Mockup Loading...' />
          <img className='mockupMiniImage' onClick={ () => setActiveMockup(activeMockupURLs.fourth)} src={activeMockupURLs.fourth} alt='Mockup Loading...' />
        </div>
        <img className="mockupImage" src={activeMockup} alt='Mockup Loading...' />
      </div>
      {/* {index === 10 ?
      <>
        <div className='mobileBlanketSelection'>
          <RadioBtnSize className="mobileChooseSize" product={product} setProduct={setProduct} />
          <DropdownBlanket className="mobileChooseBlanket" product={product} setProduct={setProduct} />
        </div>
        <div className='productName'>
          <strong className='productBlanketName' >{info.name}</strong>
          <label className='productPriceForMockup'>${info.price/100} USD</label>
        </div>
        <button className='nextButton' onClick={() => {setIndex(index + 1)}} style={{width: '88%', margin: '40px auto' }}>BUY NOW</button>
      </>
      : 
      <div className='productName' style={{margin: '30px auto'}}>
        <strong className='productBlanketName' >{info.name}</strong>
        <PriceTag className='productPriceTag' price={info.price} />
      </div>} */}
    </div>
  )
}