import React, { useContext, useEffect, useState } from "react";
import { Context } from '../App.js'

import {
  Elements,
  AddressElement,
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import axios from "axios";
import './CheckoutForm.css'
import OrderSummary from "./OrderSummary";

import { debounce } from "lodash";


import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_test_51OgFJ4ESB60VJJmdzWbOi0gZyToU8wIlCNv1gA1QOswEdCj5oqTUzgOB0t4dF3bXs5o3D800kE1HNyzlCdWsEEgQ00IJwCpXyQ");

function PaymentForm( {product, shipping, price, index, setIndex, clientSecret, svg} ) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000/transaction-successful",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else if (email === "") {
      setMessage("Your email is incomplete.")
      setValidEmail(false)
    } else {
      setMessage("An unexpected error occurred.");
    } 

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  const [country, setCountry] = useState()
  const [shippingPrice, setShippingPrice] = useState()

  const getShipping = async (country) => {
    if (shipping.hasOwnProperty(country)) {
      console.log(shipping[country])
      setShippingPrice(shipping[country])
    } else {
      setShippingPrice(shipping.other)
    }


    stripe.retrievePaymentIntent(clientSecret)
    .then(({ paymentIntent }) => {
      // Once you have the Payment Intent ID, make the POST request
      fetch("https://server-5ei7ectbla-uc.a.run.app/update_country", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 'id': paymentIntent.id, 'country': country })
      })
      .catch((error) => console.error("Error:", error));
    })
    .catch((error) => console.error("Error:", error));
  }


  const updateEmail = async () => {
    stripe.retrievePaymentIntent(clientSecret)
    .then(({ paymentIntent }) => {
      fetch("https://server-5ei7ectbla-uc.a.run.app/update_email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 'id': paymentIntent.id, 'email': email })
      })
      .catch((error) => console.error("Error:", error));
    })
    .catch((error) => console.error("Error:", error));
  }
  const debouncedSetEmail = debounce((value) => {
    setEmail(value);
    updateEmail();
  }, 1000);

  return (
    <div>

      <form id="payment-form" onSubmit={handleSubmit}>
        <div style={ (index === 1 ? {} : {display: 'none'})}>
          <h4 onClick={() => setIndex(index - 1)} style={{color: '#444', textDecoration: 'underline', cursor: 'pointer', marginBottom: '0px'}}>&#9664; Back</h4>
          <h1 style={{marginTop: '0px'}}>Shipping</h1>
          <AddressElement options={{mode: 'shipping'}} onChange={(event) => {
            const newCountry = event.value.address.country;
            if (newCountry !== country) {
              setCountry(newCountry);
              getShipping(newCountry)
            }
          }}  />
          <OrderSummary price={price} shipping={shippingPrice} />
          {index === 1 ? <button className='nextButton' onClick={() => setIndex(index + 1)}>Next</button> : null}
        </div>
        <div style={ (index === 2 ? {} : {display: 'none'})}>
          <h4 onClick={() => setIndex(index - 1)} style={{color: '#444', textDecoration: 'underline', cursor: 'pointer', marginBottom: '0px'}}>&#9664; Back</h4>
          <h1 style={{marginTop: '0px'}}>Payment</h1>
          <p style={{marginBottom: '5px', color: '#30313d'}}>Email</p>
          <input className="email-input" type="email" value={email} onChange={(e) => {setEmail(e.target.value); setValidEmail(true); updateEmail();}} placeholder="email@gmail.com" style={(validEmail) ? {} : {border: '2px solid red'}} />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <OrderSummary price={price} shipping={shippingPrice} />
          <button disabled={isLoading || !stripe || !elements} id="submit" >
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
        </div>

        {/* Show any error or success messages */}
        {message && <div id="payment-message" style={{color: 'red'}}>{message}</div>}

      </form>    

    </div>

  );
}

export default function CheckoutForm( { product, shipping, price, index, setIndex } ) {
  const {options, svg} = useContext(Context)

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const svgBlob = new Blob([svg], { type: 'image/svg+xml' });
    const formData = new FormData();
    formData.append('svg', svgBlob, 'svg_file.svg');
  
    // Add other parameters to the FormData object
    formData.append('options', JSON.stringify(options)); // Assuming options is an object
    formData.append('product', product);

    // Create PaymentIntent as soon as the padge loads
    fetch("https://server-5ei7ectbla-uc.a.run.app/create_payment", {
      method: "POST",
      body: formData
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const elementOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div className="checkoutForm">
      {clientSecret && (
        <Elements options={elementOptions} stripe={stripePromise}>
          <PaymentForm product={product} shipping={shipping} price={price} index={index} setIndex={setIndex} clientSecret={clientSecret} svg={svg} />
        </Elements>
      )}
    </div>
  );
}