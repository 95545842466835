import './OrderSummary.css'
import { getSize } from './VariantFunctions.js'

export default function OrderSummary( { price, shipping } ) {
  return(
    <div>
      <p style={{marginBottom: '5px', color: '#30313d'}}>Order Summary</p>
      <div className='priceContainer' style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '10px' }}>
        <div className='subtotal'>
          <div className='priceLeft'>
            <p className='priceLabelText'>Subtotal</p>
            <p className='priceLabelText'>Shipping</p>
          </div>

          <div className='priceRight'>
            <p className='priceLabelText'>${price/100}</p>
            <p className='priceLabelText'>{shipping === 0 ? 'Free!' : ('$' + shipping/100)}</p>
          </div>
        </div>
        <hr className='line' style={{borderColor: '#6d6e78', marginBottom:"0px"}} />
        <div className='subtotal' style={{marginTop:"0px"}}>
          <div className='priceLeft'>
            <p className='priceLabelText'>Total</p>
          </div>

          <div className='priceRight'>
            <p className='priceLabelText'>${(price+shipping)/100}</p>
          </div>
        </div>
      </div>
    </div>
  )
}