import React, { Component, useContext, useEffect, useState } from "react";
import axios from "axios";
import celestial from "d3-celestial";

import { Navigate } from "react-router-dom";

import { Context } from '../App'

export default function CelestialMap( {toCart} ) {
  const {options, setMockupURL, setSVG} = useContext(Context)

  const { innerWidth: width, innerHeight: height } = window;

  let newWidth
  if (width > 1024) {
    newWidth = width * .75 * .6
  }
  else {
    newWidth = width * .95
  }

  var config = {
    width: newWidth,
    projection: "airy",
    interactive: false,
    center: [0, 0],
    background: { fill: "#1a1d28", stroke: "#fff", opacity: 1, width: 3 },
    datapath: "https://ofrohn.github.io/data/",
    stars: {
      colors: false,
      names: false,
      style: { fill: "#fff", opacity:1 },
      limit: 6,
      size: 5 * (newWidth/342)
    },
    constellations: { 
      names: false,
      lineStyle: { stroke: "#fff", width: 1.5  * (newWidth/342), opacity: 0.6 }, },
    dsos: { show: false },
    mw: {
      style: { fill:"#E6E6FA", opacity: 0.15 }
    },
    lines: {
      graticule: { show: true, stroke: "#cccccc", width: 0.6  * (newWidth/342), opacity: 0.8,   
      lon: {pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif"}, 
      lat: {pos: [""], fill: "#eee", font: "10px Helvetica, Arial, sans-serif"}},    
      equatorial: { show: false },  
      ecliptic: { show: false }  
    }
  };
  var map = celestial.Celestial();

  useEffect(() => {
    map.display(config);
  }, [])

  useEffect(() => {
    var timestamp = new Date(options.date + 'T' + options.time);
    var offset = -(new Date().getTimezoneOffset());
    map.skyview({ date: timestamp, location: [options.lat, options.lon], timezone: offset });
  }, [options.lat, options.lon, options.date, options.time])

  useEffect(() => {
    config.constellations.lines = options.constellations
    config.mw.show = options.milkyway
    config.lines.graticule.show = options.grid
    config.background.fill = options.background
    map.apply(config)
  }, [options.constellations, options.milkyway, options.background, options.grid])

  const [SVGLoaded, setSVGLoaded] = useState(false)
  const send = async () => {
    // Create JSON data to send in the POST request

    map.exportSVG(function(svg) {
      // Set global SVG
      setSVG(svg)

      // Set up fetch options with the encoded SVG
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'file': btoa(svg) })
      };
      // Send the POST request
      fetch('https://server-5ei7ectbla-uc.a.run.app/create-blanket', requestOptions)
        .then(res => res.json())
        .then(data => {
          setMockupURL(data);
        })
        .catch(error => {
          console.log(error.response.data)
      });
    });

    setSVGLoaded(true)
  }

  // When toCart is true
  useEffect(() => {
    if (toCart) {
      send();
    }
  }, [toCart]);

  return(
    <div className='starmapContainer'>
      {(toCart && SVGLoaded) && <Navigate to={'/cart'} />}
      <div id="celestial-map"></div>
    </div>
  )
}