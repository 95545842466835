import logo from './newlogoblack.png'

import './ChooseBlanket.css'
import { useContext } from 'react'
import { Context } from '../App'

import { getDate, militaryToAMPM } from './VariantFunctions'


export function DropdownBlanket({product, setProduct})
{
  return(
    <select className="dropdown" value={product} onChange={(e) => {setProduct(e.target.value)}}>
      <option value="000" style={{display: "none"}}>Velveteen Plush</option>
      <option value="001" style={{display: "none"}}>Velveteen Plush</option>
      <option value="002">Velveteen Plush</option>
      <option value="003" style={{display: "none"}}>Sherpa Fleece</option>
      <option value="004">Sherpa Fleece</option>
    </select>
  )
}

export function RadioBtnSize({product, setProduct}) {
  const selectedStyle = {fontWeight: 'bold', color: '#000', border: '3px solid #000'}

  return(
    <div className='sizeButtonContainer'>
      {(product==='000' || product==='001' || product==='002') ? 
      <>
        <button className='sizeButtonVelveteen' onClick={() => setProduct('000')} style={product === '000' ? selectedStyle : {}}>S</button>
        <button className='sizeButtonVelveteen' onClick={() => setProduct('001')} style={product === '001' ? selectedStyle : {}}>M</button>
        <button className='sizeButtonVelveteen' onClick={() => setProduct('002')} style={product === '002' ? selectedStyle : {}}>L</button> 
      </>
        : 
      <>
        <button className='sizeButtonSherpa' onClick={() => setProduct('003')} style={product === '003' ? selectedStyle : {}}>M</button>
        <button className='sizeButtonSherpa' onClick={() => setProduct('004')} style={product === '004' ? selectedStyle : {}}>L</button> 
      </>}
    </div>
  )
}

export default function ChooseBlanket( { index, setIndex, product, setProduct, info } ) {
  const {options} = useContext(Context)

  return(
    <div className='chooseBlanket'>      
      <div className='blanketSelection'>
        <p className='blanket-select-text'>Select Size</p>
        <RadioBtnSize className="radioBtn-choose-size" product={product} setProduct={setProduct} />
        <p className='blanket-select-text'>Select Blanket</p>
        <DropdownBlanket className="dropdown-choose-blanket" product={product} setProduct={setProduct} />
      </div>
      <div className='productName'>
        <strong className='productBlanketName' >{info.name}</strong>
        <p className='productPriceForMockup'>${info.price/100} USD</p>
        <p className='productPriceForMockup'>{(options.address !== '') ? options.address : 'No Location Selected'}</p>
        <p className='productPriceForMockup'>{getDate(options.date)} {militaryToAMPM(options.time)}</p>
      </div>
      <button className='nextButton' onClick={() => {setIndex(index + 1)}} style={{margin: '0 auto' }}>BUY NOW</button>  
    </div>
  )
}